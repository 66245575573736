import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AccountService} from '../_services/account.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private accountService: AccountService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      console.log(err);

      if (err.status === 401) {
        // auto logout if 401 response returned from api
        alert('No Network');
      }

      if (err.status === 404) {
        if (err.error.errors && err.error.errors.page_not_found) {
          return throwError(err.error.errors.page_not_found[0]);
        }
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
