<form [formGroup]="stepsForm" class="fullHeight" (ngSubmit)="finishSteps(stepsForm)">
  <mat-horizontal-stepper [linear]="false" #stepper>
    <mat-step [stepControl]="welcomeFormGroup">
      <div class="row fullHeight">
        <div class="col-md-8 contentColumn">
          <app-logo></app-logo>
          <h1 class="mainTitle"><span class="titleLine"></span><span>מבדק התאמה למועמד</span></h1>
          <div class="row no-gutters">
            <div class="col">
              <article>
                <p class="mainContent mb-3">
                  <span>מועמד יקר,</span><br>
                  זהו שאלון אבחוני שמטרתו לבדוק לעומק את הפרופיל שלך על מנת לאתר באיזה תחום תוכל להצליח
                  ולוודא
                  שאתה
                  במקום הנכון עבורך
                </p>
              </article>
            </div>
          </div>
          <div class="row no-gutters footerRow">
            <div class="col-12 text-left">
              <button class="btn btn-primary mr-auto mainBtn btn-lg mt-3" matStepperNext>
                <i class="fa fa-arrow-circle-left arrowIn"></i><span class="btnTxt">בואו נתחיל</span><i
                class="fa fa-arrow-circle-left arrowOut"></i></button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="quote mb-3 mt-5 mt-1">
                *השאלון פותח במיוחד עבור חברת האקריו על ידי נטע גולן - nlp מאסטר. ובשימוש בלעדי של המכללה לצורך הכוונת
                מועמדים למקצועות ההיי טק. הפרטים הינם חסויים, נשמרים במאגרי החברה ואינם מועברים לצד שלישי.
              </p>
              <p class="quote genderDisclaimer mb-4 mt-1">*נכתב בלשון זכר מטעמי נוחות בלבד, אך מיועד לנשים וגברים
                כאחד</p>
            </div>
          </div>
          <div class="bottomFloating">
            <div class="text-right">
              <img src="assets/blue_pattern.svg" class="blue-pattern" alt="">
              <span class="start-placeholder">התחל</span>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-none d-md-block stepBGSets stepBG">
          <img src="assets/white_pattern.svg" class="white-pattern" alt="">
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="stepsForm" *ngFor="let a of examProps; let indx = index">
      <div class="row fullHeight actionPage">
        <div class="col-md-8 contentColumn">
          <app-logo></app-logo>
          <h1 class="mainTitle"><span class="titleLine"></span><span class="innerTxt" [innerHTML]="a.title"></span></h1>
          <div class="pb-1 propsWrap {{ a.type ? 'smallCheckboxWrap' : '' }}">
            <div class="row propsRow">
              <div (click)="clickAnswer($event);" class="{{ a.type ? 'col-auto smallCheckbox' : 'col-md-6' }}"
                   *ngFor="let b of a.object; let index = index">
                <input class="checkbox-budget" type="radio" value="{{b.id}}"
                       formControlName="{{a.name}}"
                       id="{{a.name}}-{{b.id}}">
                <label *ngIf="!a.type" class="for-checkbox-budget radioInputsCount bg{{ index + 1 }}"
                       for="{{a.name}}-{{b.id}}">
                  <span class="propName" [innerHTML]="b.name"></span>
                </label>
                <label *ngIf="a.type" class="for-checkbox-budget radioInputsCount scaleNumberLabel bg{{ index + 1 }}"
                       for="{{a.name}}-{{b.id}}">
                  <span><span class="scaleNumber">{{ index + 1}}</span><span class="propName"
                                                                             [innerHTML]="b.name"></span></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row no-gutters innerActionBtn">
            <div class="col-auto mb-3 mb-md-0">
              <button class="btn btn-primary mainBtn prevBtn btn-block btn-md-normal" matStepperPrevious>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span>הקודם</span></button>
            </div>
            <div class="col-auto">
              <button [disabled]="loader" class="btn btn-primary mr-auto mainBtn btn-lg"
                      (click)="validateStep(a.name, indx != examProps.length - 2)"
                      [type]="'submit'">
                <i class="fa fa-arrow-circle-left arrowIn"></i><span class="btnTxt">הבא</span><i
                class="fa fa-arrow-circle-left arrowOut"></i></button>
            </div>
            <img src="assets/blue_pattern.svg" class="blue-pattern d-none d-md-block" alt="">
          </div>
          <div class="bottomFloating current-{{ indx + 1 }}">
            <span class="start-placeholder"><span class="placeHolder">{{ indx + 1 }}</span></span>
            <div class="pageCounter"><span
              class="cur">{{ indx + 1 }}</span>/<span
              class="from">{{ examProps.length }}</span></div>
          </div>
        </div>
        <div class="col-md-4 {{ a.image }} stepBGSets d-none d-md-block">
          <img src="assets/white_pattern.svg" class="white-pattern" alt="">
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="thanksFormGroup">
      <div class="row thankyouStep">
        <div class="col-md-8 contentColumn">
          <app-logo></app-logo>
          <h1 class="mainTitle mainTitleTop"><span class="titleLine"></span><span>התשובות לשאלות</span></h1>
          <h1 class="mainTitle"><span>התקבלו בהצלחה</span></h1>
          <div class="row no-gutters innerActionBtn">
            <div class="col">
              <article>
                <p class="mainContent mb-3">
                  <span>יועץ ליווי מועמדים ייצור איתך קשר בעוד מספר ימים לגבי תוצאות השאלון</span>
                </p>
              </article>
            </div>
            <img src="assets/blue_pattern.svg" class="blue-pattern" alt="">
          </div>
          <div class="bottomFloating">
            <div class="text-right">
              <span class="start-placeholder">בהצלחה</span>
            </div>
          </div>
        </div>
        <div class="col-md-4 stepBGSets stepBG d-none d-md-block">
          <img src="assets/white_pattern.svg" class="white-pattern" alt="">
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</form>
