import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../_services/alert.service';
import {AccountService} from '../_services/account.service';
import {first} from 'rxjs/operators';
import {MatStepper} from '@angular/material/stepper';
import {Router, ActivatedRoute, Params} from '@angular/router';

export interface Generic {
  id: number;
  name: string;
  type?: number;
}

export interface ExamProps {
  name: string;
  image: string;
  object: Generic[];
  controller: any;
  title: string;
  lowToHigh?: boolean;
  type?: boolean;
}

@Component({
  selector: 'app-personality-stepper',
  templateUrl: './personality-stepper.component.html',
  styleUrls: ['./personality-stepper.component.scss']
})
export class PersonalityStepperComponent implements OnInit, AfterViewInit {
  firstFormGroup: any = '';
  secondFormGroup: any = '';
  thirdFormGroup: any = '';
  welcomeFormGroup: any = '';
  thanksFormGroup: any = '';
  fourthFormGroup: any = '';
  fifthFormGroup: any = '';
  sixthFormGroup: any = '';
  seventhFormGroup: any = '';
  ninethFormGroup: any = '';
  tenthFormGroup: any = '';
  elevenFormGroup: any = '';
  twelveFormGroup: any = '';
  thirteenFormGroup: any = '';
  fourteenthFormGroup: any = '';
  fifteenFormGroup: any = '';
  sixteenthFormGroup: any = '';
  seventeenthFormGroup: any = '';
  eighteenFormGroup: any = '';
  submitted = false;
  loader = false;
  id: string | undefined;
  pos: string | undefined;
  sd: string | undefined;

  education: Generic[] = [
    {id: 1, name: 'ללא בגרות'},
    {id: 2, name: 'בגרות חלקית'},
    {id: 3, name: 'בגרות מלאה'},
    {id: 4, name: 'תואר ראשון'},
    {id: 5, name: 'תואר שני'},
  ];
  educationGoals: Generic[] = [
    {id: 1, name: 'הסבה מקצועית'},
    {id: 2, name: 'שדרוג מקצועי'},
    {id: 3, name: 'קריירה ראשונה'},
    {id: 4, name: 'ידע אישי'},
    {id: 5, name: 'אחר'},
  ];
  importance: Generic[] = [
    {id: 1, name: 'שיעריכו אותי ושיתגמלו אותי בהתאם', type: 1},
    {id: 2, name: 'משרה בטוחה לאורך זמן עם שכר נאה', type: 1},
    {id: 3, name: 'שיהיה לי מעניין, חשוב לי לממש את עצמי', type: 1},
    {id: 4, name: 'שתהיה לי סביבת עבודה טובה ונעימה', type: 1},
  ];
  creativity: Generic[] = [
    {id: 1, name: 'מתייעץ עם מלא אנשים ומחליט לבד', type: 1},
    {id: 2, name: 'מתייעץ עם הרבה אנשים ומקבל כאב ראש', type: 1},
    {id: 3, name: 'לא מתייעץ עם אף אחד ומגיע להחלטה', type: 1},
    {id: 4, name: 'הולך למומחה בתחום שאומר לי מה לעשות', type: 1},
  ];
  educationMotive: Generic[] = [
    {id: 1, name: 'כשאני קולט שאני תקוע במקום יותר מדי זמן', type: 1},
    {id: 2, name: 'כשקורה לי משהו שמחייב אותי לזוז', type: 1},
    {id: 3, name: 'כשאני מבין שאני יכול יותר', type: 1},
    {id: 4, name: 'כשמגיעה אלי הזדמנות טובה יותר', type: 1},
  ];
  wayOfThinking: Generic[] = [
    {id: 1, name: 'אלך עם ה- Waze בכל מקרה', type: 2},
    {id: 2, name: 'אבחר במסלול שאני מכיר ויודע', type: 3},
    {id: 3, name: 'אבדוק מסלולים אחרים שה- Waze מציע ודרכים חלופות באפליקציות אחרות', type: 4},
  ];
  wayOfLearning: Generic[] = [
    {id: 1, name: 'אלמד ממיקודית (ספר שמסכם את החומר) או מסיכומים של חבר', type: 2},
    {id: 2, name: 'אני תמיד יודע מה המורה הולכת לשאול ולומד את זה', type: 3},
    {id: 3, name: 'אני מחבר אסוציאציות לכל נושא, מייצר כרטיסיות עם תמונות שמקלות עלי לזכור', type: 4},
  ];
  responsibility: Generic[] = [
    {id: 1, name: 'אחקור מה בוצע במסיבות קודמות שהייתי ואתאים את התכנים', type: 2},
    {id: 2, name: 'אבדוק מה התקציב ואארגן את הפעילויות בהתאם', type: 3},
    {id: 3, name: 'אעלה רעיונות למשחקים ופעילויות ואארגן הכל בעצמי', type: 4},
  ];
  organized: Generic[] = [
    {id: 1, name: 'אחפש מסלול מוכן באינטרנט וקדימה לטיול', type: 2},
    {id: 2, name: 'אתעניין במסלולים שיש ברשת ואבחר אטרקציות שמתאימות לתקציב של כל החברים', type: 3},
    {id: 3, name: 'אכנס לכל הקבוצות הרלוונטיות, אשאל אנשים ואצור את המסלול הייחודי לנו', type: 4},
  ];
  challenges: Generic[] = [
    {id: 1, name: 'מתעמק בפרטים הקטנים ומנסה להבין איך לפצח את זה', type: 2},
    {id: 2, name: 'מבקש עזרה ממי שיודע או מחפש באינטרנט חומרים', type: 3},
    {id: 3, name: 'מסתכל רגע על הדברים ממרחק ויודע שיגיע לי הרעיון', type: 4},
  ];
  personality: Generic[] = [
    {id: 1, name: 'תמיד אהבתי להבין איך הדברים עובדים מבפנים', type: 2},
    {id: 2, name: 'אני בן אדם פרקטי מה שאני רואה זה מה שיש ועם זה ננצח', type: 3},
    {id: 3, name: 'אני תמיד יודע שאפשר להפיק עוד מכל סיטואציה, תמיד יש עוד אפשרויות', type: 4},
  ];
  friendship: Generic[] = [
    {id: 1, name: 'אנסה לחקור ולגלות את אמיתות הדבר ואת המקור שממנו גיליתי את הסוד', type: 2},
    {id: 2, name: 'פשוט אלך ואספר לו ומה שיהיה יהיה', type: 3},
    {id: 3, name: 'אדובב אותו ואנסה לשאול אותו בעקיפין אם הוא היה מעדיף לדעת או לא', type: 4},
  ];
  searchForProfessional: Generic[] = [
    {id: 1, name: 'אעשה השוואות מחירים בין החברות הגדולות ואקח את החברה הכי משתלמת עבורי', type: 2},
    {id: 2, name: 'אשאל חברים מה יותר טוב ואקח את החברה שהרוב המליצו עליה', type: 3},
    {id: 3, name: 'אשתמש בספק אחר לכל תחום ככה יעלה לי פחות כסף ויהיה קל יותר להחליף ספק בבעיה נקודתית', type: 4},
  ];
  foodReservations: Generic[] = [
    {id: 1, name: 'אארגן איזה סנדוויץ\' פשוט', type: 2},
    {id: 2, name: 'אזמין אוכל', type: 3},
    {id: 3, name: 'אאלתר ארוחה מגניבה ממה שיש בבית', type: 4},
  ];
  favoriteClass: Generic[] = [
    {id: 1, name: 'הכי אהבתי לפרק ולהרכיב דברים בבית', type: 2},
    {id: 2, name: 'מתמטיקה ללא ספק', type: 3},
    {id: 3, name: 'אומנות, יצירה וכתיבה', type: 4},
  ];
  englishWell: Generic[] = [
    {id: 1, name: 'לא מבין בכלל', type: 1},
    {id: 2, name: '', type: 1},
    {id: 3, name: '', type: 1},
    {id: 4, name: '', type: 1},
    {id: 5, name: 'שפת אם', type: 1},
  ];
  crosswordPuzzle: Generic[] = [
    {id: 1, name: 'תנו לי תשבץ הגיון', type: 2},
    {id: 2, name: 'סודוקו זה אני!', type: 3},
    {id: 3, name: 'אני בכלל בקטע של חידות', type: 4},
  ];
  tieBreaker: Generic[] = [
    {id: 1, name: 'מסכים', type: 5},
    {id: 2, name: 'לא מסכים', type: 5}
  ];

  examProps: ExamProps[] = [];
  stepsForm: FormGroup;
  props: any = {
    education: this.education,
    educationGoals: this.educationGoals,
    importance: this.importance,
    creativity: this.creativity,
    educationMotive: this.educationMotive,
    wayOfThinking: this.wayOfThinking,
    wayOfLearning: this.wayOfLearning,
    responsibility: this.responsibility,
    organized: this.organized,
    challenges: this.challenges,
    personality: this.personality,
    friendship: this.friendship,
    searchForProfessional: this.searchForProfessional,
    foodReservations: this.foodReservations,
    favoriteClass: this.favoriteClass,
    englishWell: this.englishWell,
    crosswordPuzzle: this.crosswordPuzzle,
    tieBreaker: this.tieBreaker,
  };

  oneType: Generic[] = [];
  resultValues: any = {
    techPerson: [],
    realisticPerson: [],
    creativePerson: [],
    oneType: [],
    tieBreaker: [],
  };
  answers = [];
  hash = '';

  @ViewChild('stepper') private myStepper: MatStepper | undefined;
  @ViewChild('stepper', {read: ElementRef}) public myScrollContainer: ElementRef<any> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.stepsForm = this.formBuilder.group({
      education: ['', Validators.required],
      educationGoals: ['', Validators.required],
      importance: ['', Validators.required],
      creativity: ['', Validators.required],
      educationMotive: ['', Validators.required],
      wayOfThinking: ['', Validators.required],
      wayOfLearning: ['', Validators.required],
      responsibility: ['', Validators.required],
      organized: ['', Validators.required],
      friendship: ['', Validators.required],
      searchForProfessional: ['', Validators.required],
      personality: ['', Validators.required],
      challenges: ['', Validators.required],
      foodReservations: ['', Validators.required],
      favoriteClass: ['', Validators.required],
      crosswordPuzzle: ['', Validators.required],
      englishWell: ['', Validators.required],
      tieBreaker: [''],
    });
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.data) {
        localStorage.setItem('hckhashdata_data', params.data);
        // location.href = '/';
      }
      if (params && Object.keys(params).length > 0) {
        const urlWithoutQueryParams = this.router.url.substring(0, this.router.url.indexOf('?'));
        this.router.navigateByUrl(urlWithoutQueryParams)
          .then(() => {
          });
      }
    });

    /*this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      this.hash = params.data;
      if (this.hash) {
        localStorage.setItem('hckhashdata_data', this.hash);
        // location.href = '/';
      }
    });*/
  }

  ngOnInit(): void {
    this.education = this.education.sort(() => Math.random() - 0.5);
    this.educationGoals = this.educationGoals.sort(() => Math.random() - 0.5);
    this.importance = this.importance.sort(() => Math.random() - 0.5);
    this.creativity = this.creativity.sort(() => Math.random() - 0.5);
    this.educationMotive = this.educationMotive.sort(() => Math.random() - 0.5);
    this.wayOfThinking = this.wayOfThinking.sort(() => Math.random() - 0.5);
    this.wayOfLearning = this.wayOfLearning.sort(() => Math.random() - 0.5);
    this.responsibility = this.responsibility.sort(() => Math.random() - 0.5);
    this.organized = this.organized.sort(() => Math.random() - 0.5);
    this.friendship = this.friendship.sort(() => Math.random() - 0.5);
    this.searchForProfessional = this.searchForProfessional.sort(() => Math.random() - 0.5);
    this.personality = this.personality.sort(() => Math.random() - 0.5);
    this.challenges = this.challenges.sort(() => Math.random() - 0.5);
    this.foodReservations = this.foodReservations.sort(() => Math.random() - 0.5);
    this.favoriteClass = this.favoriteClass.sort(() => Math.random() - 0.5);
    this.crosswordPuzzle = this.crosswordPuzzle.sort(() => Math.random() - 0.5);

    this.examProps = [
      {
        image: 'stepBG1',
        object: this.education,
        controller: this.firstFormGroup,
        name: 'education',
        title: 'מהי השכלתך?'
      },
      {
        image: 'stepBG2',
        object: this.educationGoals,
        controller: this.secondFormGroup,
        name: 'educationGoals',
        title: 'מה המטרה שלך בלימודים בהאקריו?'
      },
      {
        image: 'stepBG3',
        object: this.importance,
        controller: this.thirdFormGroup,
        name: 'importance',
        title: '<span>מה הכי חשוב לך במקום העבודה שלך?</span><br>\n' +
          '<span>(ברור שהכל, אבל צריך לבחור מה הכי הכי)</span>\n'
      },
      {
        image: 'stepBG4',
        object: this.creativity,
        controller: this.fourthFormGroup,
        name: 'creativity',
        title: 'כשיש לך התלבטות איך אתה פותר אותה?'
      },
      {
        image: 'stepBG5',
        object: this.educationMotive,
        controller: this.fifthFormGroup,
        name: 'educationMotive',
        title: 'מה הדבר שמניע אותך לכיוון חדש?'
      },
      {
        image: 'stepBG6',
        object: this.wayOfThinking,
        controller: this.sixthFormGroup,
        name: 'wayOfThinking',
        title: '<span>קבעת עם החברים שלך להיפגש לסרט,</span><br>\n' +
          '                <span>ה- Waze מראה שיש סיכוי סביר שאתה הולך לאחר</span><br>\n' +
          '                <span>באיזו דרך תבחר להגיע ליעד?</span>\n' +
          '              '
      },
      {
        image: 'stepBG8',
        object: this.wayOfLearning,
        controller: this.seventhFormGroup,
        name: 'wayOfLearning',
        title: 'יש לך מבחן ידע גדול ואין לך הרבה זמן, איך תלמד אליו?'
      },
      {
        image: 'stepBG9',
        object: this.responsibility,
        controller: this.ninethFormGroup,
        name: 'responsibility',
        title: 'חבר טוב מינה אותך כאחראי על מסיבת הרווקים שלו:'
      },
      {
        image: 'stepBG10',
        object: this.organized,
        controller: this.ninethFormGroup,
        name: 'organized',
        title: 'אתה צפוי לצאת לטיול בחו"ל עם חברים:'
      },
      {
        image: 'stepBG11',
        object: this.challenges,
        controller: this.tenthFormGroup,
        name: 'challenges',
        title: 'איך אתה ניגש לאתגרים שקשה לך להתמודד איתם?'
      },
      {
        image: 'stepBG12',
        object: this.personality,
        controller: this.elevenFormGroup,
        name: 'personality',
        title: 'איזה מהמשפטים מתאר אותך הכי טוב?'
      },
      {
        image: 'stepBG13',
        object: this.friendship,
        controller: this.twelveFormGroup,
        name: 'friendship',
        title: 'גילית סוד שקשור לחבר שלך ואתה מתלבט אם לשתף אותו:'
      },
      {
        image: 'stepBG14',
        object: this.searchForProfessional,
        controller: this.thirteenFormGroup,
        name: 'searchForProfessional',
        title: 'עברת דירה ואתה מחפש בעלי מקצוע לשיפוצים קלים איך תעשה את זה?'
      },
      {
        image: 'stepBG15',
        object: this.foodReservations,
        controller: this.fourteenthFormGroup,
        name: 'foodReservations',
        title: 'מגיע הערב ויש לך רעב לאוכל טעים ולא התארגנת מראש, מה בדרך כלל יקרה?'
      },
      {
        image: 'stepBG16',
        object: this.favoriteClass,
        controller: this.fifteenFormGroup,
        name: 'favoriteClass',
        title: 'לאיזה מקצוע הכי התחברת בתקופת התיכון?'
      },
      {
        image: 'stepBG17',
        object: this.englishWell,
        controller: this.sixteenthFormGroup,
        name: 'englishWell',
        title: 'כשאתה קורא טקסטים באנגלית מה רמת ההבנה שלך?',
        lowToHigh: true,
        type: true
      },
      {
        image: 'stepBG18',
        object: this.crosswordPuzzle,
        controller: this.seventeenthFormGroup,
        name: 'crosswordPuzzle',
        title: 'איזה סוג תשבץ אתה מעדיף?',
      },
      {
        image: 'stepBG18',
        object: this.tieBreaker,
        controller: this.eighteenFormGroup,
        name: 'tieBreaker',
        title: '',
      },
    ];
  }

  goBack(): void {
    this.myStepper?.previous();
  }

  move(index: number): void {
    if (this.myStepper) {
      this.myStepper.selectedIndex = index;
    }
  }

  getProductById(id: number): any {
    return this.education.find(p => p.id === id);
  }

  log(a: any): any {
    console.log(a);
  }

  validateStep(name: string, position: boolean): void {
    // $('body')[0].scrollTop = 0;
    // @ts-ignore
    // $('body').animate({scrollTop: 0}, 'fast', () => this.myStepper?.next());

    const hashdata = localStorage.getItem('hckhashdata_data');
    if (!hashdata) {
      this.alertService.error('מזהה חסר. פנה בבקשה לתמיכה!');
      return;
    }

    if (!this.stepsForm.value[name] || !this.props[name]) {
      this.alertService.error('בחר אחת מהאפשרויות בבקשה');
      return;
    }
    const msgType = this.props[name].find((e: any) => e.id === parseInt(this.stepsForm.value[name], 10));
    if (!msgType) {
      this.alertService.error('בחר אחת מהאפשרויות בבקשה');
      return;
    }
    if (position) {
      // @ts-ignore
      if (this.myStepper?.selectedIndex <= 16) {
        // document.body.scrollTop = 0;
        this.myStepper?.next();
      }
    }
  }

  ngAfterViewInit(): void {
    // this.move(6);
  }

  checkResult(values: any): boolean {
    this.resultValues.oneType = [];
    this.resultValues.techPerson = [];
    this.resultValues.realisticPerson = [];
    this.resultValues.creativePerson = [];
    this.resultValues.tieBreaker = [];
    for (const property in values) {
      if (values.hasOwnProperty(property)) {
        const msgType = this.props[property].find((e: any) => e.id === parseInt(this.stepsForm.value[property], 10));
        if (msgType) {
          switch (parseInt(msgType.type, 10)) {
            case 1:
              this.resultValues.oneType.push(msgType.name);
              break;
            case 2:
              this.resultValues.techPerson.push(msgType.name);
              break;
            case 3:
              this.resultValues.realisticPerson.push(msgType.name);
              break;
            case 4:
              this.resultValues.creativePerson.push(msgType.name);
              break;
            case 5:
              this.resultValues.tieBreaker.push(msgType.name);
              break;
          }
        }
      }
    }
    if (this.resultValues.techPerson.length > 0 &&
      this.resultValues.realisticPerson.length > 0 &&
      this.resultValues.techPerson.length === this.resultValues.realisticPerson.length
      && this.resultValues.realisticPerson.length > this.resultValues.creativePerson.length) {
      this.examProps[17].title = 'אני נחשב לאדם ריאלי';
    } else if (this.resultValues.creativePerson.length > 0 &&
      this.resultValues.realisticPerson.length > 0 &&
      this.resultValues.creativePerson.length === this.resultValues.realisticPerson.length &&
      this.resultValues.realisticPerson.length > this.resultValues.techPerson.length) {
      this.examProps[17].title = 'אני נחשב לאדם ריאלי';
    } else if (this.resultValues.techPerson.length > 0 &&
      this.resultValues.creativePerson.length > 0 &&
      this.resultValues.creativePerson.length === this.resultValues.techPerson.length &&
      this.resultValues.creativePerson.length > this.resultValues.realisticPerson.length) {
      this.examProps[17].title = 'אני נחשב לאדם טכני';
    } else {
      return true;
    }

    return false;
  }

  finishSteps(form: any): void {
    // @ts-ignore
    // setTimeout(() => $('body')[0].scrollTop = 0, 1500);
    this.submitted = true;
    this.alertService.clear();
    if (this.stepsForm.invalid) {
      return;
    }
    const needTie = this.checkResult(this.stepsForm.value);
    if (!needTie) {
      if (this.myStepper?.selectedIndex === 17) {
        this.move(18);
        return;
      }
      if (!this.stepsForm.get('tieBreaker')?.value) {
        this.alertService.error('בחר אחת מהאפשרויות בבקשה');
        return;
      }
    }

    // @ts-ignore
    if (!needTie && this.myStepper?.selectedIndex < 18) {
      return;
    } else { // @ts-ignore
      if (needTie && this.myStepper?.selectedIndex < 17) {
        return;
      }
    }

    const hashdata = localStorage.getItem('hckhashdata_data');
    if (!hashdata) {
      this.alertService.error('מזהה חסר. פנה בבקשה לתמיכה!');
      return;
    }

    this.loader = true;
    this.accountService.sendForm(this.stepsForm.value, hashdata, this.resultValues)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.move(19);
          this.loader = false;
        },
        error: error => {
          this.alertService.error(error);
          this.loader = false;
          // this.myStepper?.previous();
        }
      });
  }

  clickAnswer($event: MouseEvent): void {
    // @ts-ignore
    $('html, body').animate({scrollTop: $(document).height()}, 1000);
  }
}
