import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public homeUrl = environment.apiUrl + '/wp-json/ebtech-modules/v1';

  constructor(
    private router: Router,
    private http: HttpClient) {
  }

  sendForm(formValues: any, hash: string, rangedValues: any): Observable<any> {
    return this.http.post(`${this.homeUrl}/new-personality-exam`, {data: formValues, hash, rangedValues});
  }

}
